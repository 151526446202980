import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';
import { Splide } from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import '@splidejs/splide/dist/css/splide-core.min.css';

import.meta.glob([
    '../svg/logos/**',
]);

window.Alpine = Alpine

Alpine.data('slider', () => ({
    init() {
        new Splide(this.$el, {
            type        : 'loop',
            autoWidth   : true,
            pagination  : false,
            arrows      : false,
        }).mount( { AutoScroll } );
    }
}))

Livewire.start()

